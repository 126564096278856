<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="addItem">New</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="flights"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.departure_date_time="{ item }">
        {{ formatDateAndTime(item.departure_date_time) }}
      </template>
      <template v-slot:item.arrival_date_time="{ item }">
        {{ formatDateAndTime(item.arrival_date_time) }}
      </template>
      <template v-slot:item.subgroups="{ item }">
        <span v-for="(subgroup, index) in item.subgroups" :key="subgroup.id" @click.stop>
          <router-link :to="`/subgroups/${subgroup.id}`" class="base-link">{{ subgroup.title }}</router-link>
          <span v-if="index != item.subgroups.length - 1">, </span>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />

    <v-dialog width="640" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Flight Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <FlightDetailForm
            :group_id="subgroup.group_id"
            :subgroup_id="subgroup.id"
            :flight_id="flight_id"
            :isvisible="showManageDialog"
            :items-per-page="items_per_page"
            @closed="onFlightMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import FlightDetailForm from '@/views/FlightDetailForm';
import Paginate from '@/views/Paginate';
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'GroupFlightList',
  mixins: [dateMixin],
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('flight/list', {
        subgroup_id: to.params.id,
        page: currentPage,
        per_page: 100,
        sort_by: 'departure_date_time',
        sort_desc: false
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  components: {
    FlightDetailForm,
    Paginate,
    ConfirmDialog
  },
  methods: {
    addItem: function () {
      this.flight_id = 'new';
      this.showManageDialog = true;
    },
    editItem(item) {
      this.flight_id = item.id;
      this.showManageDialog = true;
    },
    deleteItem(item) {
      this.flight_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('flight/delete', this.flight_id);
      this.load();
    },
    onFlightMangageDialogClosed() {
      this.showManageDialog = false;
      this.load();
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('flight/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('flight/paginate', this.links.prev);
      }
    },
    load() {
      if (this.subgroup.id) {
        store.dispatch('flight/list', {
          subgroup_id: this.subgroup.id,
          page: this.options.page,
          per_page: this.items_per_page,
          sort_by: this.options.sortBy[0] ?? 'id',
          sort_desc: this.options.sortDesc[0] ?? false
        });
      }
    }
  },
  computed: {
    ...mapGetters('flight', ['loading', 'error', 'flights', 'meta', 'links']),
    ...mapGetters('subgroup', ['subgroup'])
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          this.load();
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    showManageDialog: false,
    showDeleteConfirmDialog: false,
    flight_id: null,
    items_per_page: 100,
    options: {},
    headers: [
      { text: 'Departs', value: 'departure_date_time' },
      { text: 'Departs From', value: 'origin' },
      { text: 'Flight', value: 'flight_number' },
      { text: 'Airline', value: 'airline' },
      { text: 'Arrives At', value: 'destination' },
      { text: 'Arrives', value: 'arrival_date_time' },
      { text: 'Sub Group', value: 'subgroups' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
